<template>
  <article class="profile-tab__content">
    teste
  </article>
</template>

<script>
export default {
  name: 'UserProfilePassword',
};
</script>
